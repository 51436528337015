<template>
  <div>
    支付成功，正在跳转...
  </div>
</template>
<script>

export default {
  name: "PayFinish",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$router.push({ name: "OrderList", params: { proxyCode: this.$store.state.proxyCode } });
  },
};
</script>